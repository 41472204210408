
.icon-list {
  margin:auto;
  width:90%;
}

.fertility-start {
  display: flex;
  height: calc(100vw * (667 / 1000));
  padding:0!important;
  max-height: 808px!important;
  @media only screen and (max-width: $tablet) {
    height: calc(100vw * (300 / 500));
  }
}

.start-sexy-img {
  display: flex;
  //height: calc(100vw * (667 / 1000));
  height: calc(100vw * (300 / 545));
  padding:0!important;
  max-height: 668px!important;
}

.fertility-title-img {
  height:auto;
  width: 100vw;
  position: absolute;
  max-width: $widescreen;
}

.fertility-title-text {
  position:relative;
  padding-top: calc(50vw * 279 / 375);
  @media only screen and (min-width: $widescreen) {
    padding-top: 315px;
  }
  @media only screen and (max-width: $tablet) {
    padding-top: calc(60vw * 300 / 500);
  }
}

.fertility-title-text-sexy-img {
  position:relative;
  // padding-top: calc(50vw * 279 / 375);
  padding-top: calc(60vw * 300 / 700);
  h1 {
    font-size: 6vw!important;
  }
  @media only screen and (min-width: $widescreen) {
    padding-top: 315px;
    h1 {
      font-size:73px!important;
    }
  }
  @media only screen and (max-width: $tablet) {
    // padding-top: calc(60vw * 300 / 700);
  }
}

.icon-dl {
  width: 25px;
  height: auto;
}

.carousel-fert {
  height:auto;
  width:30vw;
  max-width: 325px;
  margin-bottom: 2rem;
  @media only screen and (max-width: $tablet) {
    width: 60vw;
  }
}

// override slide-container for discover the app carousel
#carousel-fertility .slider-container {
  margin:auto;
}

.mobile-img {
  height: auto;
  max-height: 525px;
  @media only screen and (max-width: $tablet) {
    width: 100vw;
    max-width: 86vw;
    margin-left: -16px;
    height: auto;
  }
}
