/*
This .scss loop will create "margin helpers" and "padding helpers".
It will generate several classes such as:
.mr-10 gives MARGIN to the RIGHT 10 pixels.
.mt-10 gives MARGIN to the TOP 10 pixels and so on.
.pb-10 gives PADDING to the BOTTOM of 10 pixels
.pl-40 gives PADDING to the LEFT of 40 pixels
The first letter is "m" or "p" for MARGIN or PADDING
Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.
*/

$spaceamounts: (auto, 0, 5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right, x, y); // Leave these variables alone

@mixin spacing-rule($param, $side, $space) {
	@if $space != "auto" {
		$space: #{$space}px;
	}

	@if $side == "x" {
		#{$param}-left: #{$space} !important;
		#{$param}-right: #{$space} !important;
	} @else if $side == "y" {
		#{$param}-top: #{$space} !important;
		#{$param}-bottom: #{$space} !important;
	} @else {
		#{$param}-#{$side}: #{$space} !important;
	}
}

@each $space in $spaceamounts {
	@each $side in $sides {
		.m#{str-slice($side, 0, 1)}-#{$space} {
			@include spacing-rule(margin, $side, $space);
		}

		.p#{str-slice($side, 0, 1)}-#{$space} {
			@include spacing-rule(padding, $side, $space);
		}

		@media (min-width: $tablet) {
			.m#{str-slice($side, 0, 1)}-#{$space}-tablet {
				@include spacing-rule(margin, $side, $space);
			}

			.p#{str-slice($side, 0, 1)}-#{$space}-tablet {
				@include spacing-rule(padding, $side, $space);
			}
		}

		@media (min-width: $desktop) {
			.m#{str-slice($side, 0, 1)}-#{$space}-desktop {
				@include spacing-rule(margin, $side, $space);
			}

			.p#{str-slice($side, 0, 1)}-#{$space}-desktop {
				@include spacing-rule(padding, $side, $space);
			}
		}
	}
}
