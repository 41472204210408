@import '~bulma/sass/utilities/initial-variables';

// Variables
$gap: 32px;
$desktop: 1000px + (2 * $gap);
$widescreen-enabled: false;
$fullhd-enabled: false;
$section-padding: 1.5rem 1rem;
$section-padding-medium: 5rem 2rem;
$tablet: 500px;
$iphoneSE: 320px;
$block-spacing: 1.5rem;

// Colors
$black: hsl(0, 0%, 4%);
$black-bis:    hsl(0, 0%, 7%);
$black-ter: hsl(0, 0%, 14%);

$grey-darker: hsl(0, 0%, 20%);
$grey-dark: hsl(0, 0%, 35%);
$grey: hsl(0, 0%, 48%);
$grey-light: hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);

$white-ter: hsl(0, 0%, 96%);
$white-bis: hsl(0, 0%, 98%);
$white:        hsl(0, 0%, 100%);

$dark: $grey-darker;
$text: $grey-dark;
$primary: #FF6E8C;

$text-light: $grey;
$text-color: $dark;
$text-color-2: #a0a0a0;
$text-color-3: #646464;
$link: #EE778D;
$text-highlight-color: #C4536a;
$dark-color: #000000;
$white: #FFF;

$weight-light: 200;
$weight-normal: 300;
$weight-medium: 400;
$weight-semibold: 500;
$weight-bold: 600;

$body-background-color: $white-ter;

$block-primary-color: $white-ter;
$block-secondary-color: #FFF;
$gradient-left-color: #B95091;
$gradient-left-color2: #C35292;
$gradient-left-color3: #BE4B91;
$gradient-right-color: #F0879B;
$gradient-right-color2: #FFB790;
$gradient-right-color3: #FABE96;
$block-highlight-color: #9AFCE4;

$success: #FF6E8C;

// Font-sizes
$size-1: 3.75rem;
$size-2: 3.125rem;
$size-3: 2.5rem;
$size-4: 1.875rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.875rem;
$size-8: 0.55rem;


// Fonts
$system-font-family: Basetica,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
$primary-font-family: $system-font-family;
$strong-weight: 500;


// Headings
$title-color: $text;
$title-weight: 200;
$title-line-height: 1.2;
$title-strong-weight: 400;
$subtitle-color: $text;
$subtitle-weight: 200;
$subtitle-line-height: 1.3;
$subtitle-negative-margin: -1rem;


// Transition
$transition-time: 0.4s;


// Navbar
$button-height: 26px;
$button-width: 35px;
$navbar-item-hover-color : $primary;
$navbar-item-hover-background-color : transparent;
$navbar-tab-active-color : $primary;
$navbar-breakpoint: $desktop;
$navbar-background-color: $block-primary-color;

$navbar-height-mobile: 72px;
$navbar-height-tablet: 77px;
$navbar-height-desktop: 87px;


// flag-icons lib:
// flag-icons-included-countries controls which flag-icons are used - see docs
// $countries is set by us in webpack config,
$flag-icons-path: '~flag-icons/flags';
$flag-icons-included-countries: $countries;


// Buttons
$button-color: $white;
$button-background-color: $primary;
$button-disabled-background-color: $grey;


// Border
$border-light: #a0a0a0;
$border: $grey;
$border-dark: $grey-dark;


// Checkout Steps
$steps-active-color: $primary;

// Label
$label-weight: $weight-medium;


// Horizontal lines (e.g. in Checkout OrderSummary)
$hr-height: 1px;
$hr-margin: 0.5rem 0 0.5rem 0;
$hr-background-color: $grey-light;


// Box
$box-padding: 1.25rem;
