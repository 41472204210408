@import "variables";

.home__intro__headline{
  max-width: 90%;
  text-align: left;
}

.home__intro__subtext{
  max-width: 95%;
}

.home__intro__appstore{
  min-width: 8rem;
  letter-spacing: 0.5px;
}

.home__intro__appstore-download{
  margin-top: 5px;
  width: 25vw;
  height: auto;
  max-width:160px;

  @media only screen and (min-width: $desktop) {
    width: auto;
    height: 3rem;
  }
}

.home__faq {
  text-align: center;
  color: $block-secondary-color;
  margin: 0;
  background: linear-gradient(90deg, $gradient-left-color3, $gradient-right-color3);
}

.home__blog__title {
  order: 1;
}

.home__blog__img {
  order: 2;
  width: 100%;
  max-width: 6rem;
  float: left;
  margin: 0 1rem 0.5rem 0;

  @media only screen and (min-width: $desktop) {
    order: 1;
    max-width: 13rem;
    float: none;
    margin: 0 0 0.5rem 0;
  }
}

.home__blog__excerpt {
  order: 2;
  max-width: 24em;
  margin: 0 auto;
  color: $text;
}

.home__blog__post-container:hover .home__blog__post {
  opacity: 0.55;
  transform: scale(0.98);
}

.home__blog__post-container .home__blog__post:hover {
  opacity: 1;
  transform: scale(1);
}

.home__blog__post {
  transition: $transition-time;

  @media only screen and (min-width: $desktop) {
    max-width: 23em;
  }
}

.has-bg-gradient {
  background: linear-gradient(90deg, $gradient-left-color3, #F5A299);
}

.carousel-text {
  @media only screen and (max-width: $tablet) {
    margin-bottom: 13px!important;
    margin-right: 44px !important;
    margin-left: 44px !important;
  }
}

.three-steps {
  .step-img {
    width: 100%;
    max-width: 12.5rem;

    @media only screen and (min-width: $desktop) {
      max-width: 15.5rem;
    }

    img {
      border-radius: 50%;
    }
  }
}

.featured {
  .column {
    padding: 0.0rem;
    img {
      width: 60%
    }
    @media only screen and (min-width:$desktop) {
      padding: 0 0.75rem 0 0.75rem;
      width: 100%
    }
  }
}

@keyframes bm {
  from  {opacity: 0;}
  to {opacity: 1;}
}

.menu_button {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-color: transparent;
  border-radius: 25px!important;
}

.steps {
  font-size: 1.35rem;
}

.readall {
  @media only screen and (min-width: $tablet) {
    padding-top: 20px !important;
  }
}

.img_home {
  margin: auto 1rem!important;
  @media only screen and (max-width: 500px) {
    margin:0;
  }
}

.bm-img_de {
  height:auto;
}

.home-shopbtn {
  text-align:left;
  @media only screen and (max-width: $tablet) {
    text-align:center;
  }
}

.img-title {
  height:auto;
  width: 100vw;
  position: absolute;
  max-width: $widescreen;
}

.img-title-blog {
  height: auto;
  max-width: 100%;
  width: 100vw;
  position: absolute;

   @media only screen and (max-width: 500px) {
     max-width: 100vw;
     left:-16px;

   }
}

.btn {
  height: 2.5rem;
  width: calc(40vw);
  max-width: 20rem;
  border-color: rgba(0,0,0,0);
  border-radius: 25px!important;
  font-size:1.25rem;
  font-weight:300;
  border-width: 0;
  @media only screen and (max-width: $tablet) {
    width:90%;
    font-size:1.125rem;
  }
}

.btn_white {
  display:flex;
  float:right;
  @media only screen and (max-width: 500px){
    margin:auto;
    float: none;
  }
}

.bg_white {
  background: #fff;
}

.bg-gradient {
  background: linear-gradient(90deg, $gradient-left-color3, $gradient-right-color3);
  color: #fff!important;
}

.bg-gradient:hover {
  color: #ff6e8c!important;
  background: $block-primary-color!important;
}

.grey {
  color: $primary!important;
  background: $block-primary-color!important;
}

.grey:hover {
  background: linear-gradient(90deg, $gradient-left-color3, $gradient-right-color3)!important;
  color:#fff!important;
}

.dark-grey {
  color: #7f7f7f;
  border-color:#7f7f7f;
  background-color: $block-primary-color!important;
  border-width:1px!important;
  width:13rem;
  @media only screen and (max-width: 500px) {
    width: 90%;
  }
}

.pink-border-blog {
   //border-color: #ff6e8c;
   color: #ff6e8c;
   background-color: $block-primary-color!important;
   //background: linear-gradient(90deg, $gradient-left-color3, $gradient-right-color3);
   //color: #fff!important;
   border-width:1px!important;
  width:15rem;
  @media only screen and (min-width: 500px) {
    height: 2rem;

  }
  @media only screen and (max-width: 800px) {
  }
}

.button-app {
  padding-left: 1rem;
}

.title_home1 {
  margin: auto;
  line-height: 150%;

}

.title_home {
  text-align: right;
  width: 55vw;
  // margin: auto;
  margin-left: 39vw;
  padding-bottom:2rem;
  h1 {
    font-size: 4vw!important;
  }
  @media only screen and (min-width: $widescreen) {
    width:668px;
    margin-left:473px;
    h1 {
      font-size: 48px!important;
    }
  }
  @media only screen and (max-width: 500px) {
    // padding:0 1rem 1rem 0.5rem;
    margin:auto;
    text-align:center;
    width:80%;
    h1 {
      font-size:6.5vw!important;
      font-weight:400;
    }
  }
}

.title-text {
  position:relative;
  padding-top: calc(50vw * 279 / 375);
  @media only screen and (min-width: $widescreen) {
    padding-top: 455px;
  }
  @media only screen and (max-width: 500px) {
    padding-top: 100vw;
  }
}

.home-start {
  display: flex;
  height: calc(100vw * (667 / 1000));
  padding:0!important;
  max-height: 811px!important;
  @media only screen and (max-width: 500px) {
    height: calc(100vw * 889 / 500);
    //height: 50vw;
    //height: 90vw;
    // max-height: 885px!important;
  }
}

.title-mobile {
  @media only screen and (max-width: 500px) {
    display: none!important;
  }
}

.title-tablet{
  @media only screen and (min-width: 501px) {
    display: none!important;
  }
}

.btn-fix {
  @media only screen and (min-width: $tablet) {
    width: 40vw!important;
    max-width: 20rem;
  }
}

.patch-img {

  width: 150%;
  @media only screen and (min-width: $desktop) {
    width: 450px;
  }
  @media only screen and (max-width: 500px){
    max-width: 90%;
  }
}

.pcb-img {
  animation: fadein 6s infinite;
  transform: translateY(-103%);

}

.patch-mobile {
  height:30vw;
  max-height:413px;
  @media only screen and (min-width:$tablet) and (max-width: $desktop) {
    height: calc(35vw * 1.5);
  }
  @media only screen and (max-width: 500px){
    height:66vw;
    min-height:190px;
  }
}

@keyframes fadein {
  0%,100% { opacity: 0 }
  60% { opacity: 1 }
}

.home-2 {
  @media only screen and (max-width: 500px) {
    display:flex;
    flex-direction:column-reverse;
    margin-left: 7vw;
  }
}

.home-3 {
  padding: 0 0 0 1.5rem;
  margin:0 ;
  @media only screen and (max-width: 500px) {
    padding:0;
    background-color: white;
  }

}

.no-pad-col-last:last-child {
  margin-bottom:0;
}

.fert-col {
  @media only screen and (max-width: 500px) {
    padding:1.5rem;
  }
}

.img-100-mobile {
  @media only screen and (max-width:500px) {
    width:100vw;
    height:auto;
  }
}

.home-footer {
  padding: 0;
  margin:0;
}

.fert-sec1 {
  flex-direction:row-reverse;
}

.shop-now {
  height:calc(100vw * 900/500);
  padding:0!important;
  @media only screen and (min-width: 500px) {
    max-height: 347px!important;
    height: calc((100vw * 164) / 577);
  }

}

.shop-now-blog {
  height:calc(100vw * 775/500);
  padding:0!important;
  @media only screen and (min-width: 500px) {
    max-height: 250px!important;
    height: calc((100vw * 164) / 577);
  }

}

.free-shipping-banner{
  padding: 0;
  margin:0 ;

}

.shop-text{
  padding-top:3rem;
  position: relative;
  @media only screen and (min-width: 500px) {
    padding-left:10%;
    padding-top:calc(((100vw * 164) / 577) * 0.30);
  }
  @media only screen and (min-width: 800px) {
    padding-top:6rem;
  }

}

.shop-text-blog{
   padding-top: 2rem;
   position: relative;
   @media only screen and (min-width: 500px) {
     padding-left:7%;
     padding-top: calc(((100vw * 164) / 577) * 0.04);
   }
   @media only screen and (min-width: 800px) {
     padding-top:2rem;
   }
 }

 .shop-title-fix {
   margin:0!important;
   font-size: 2.5rem!important;
   @media only screen and (min-width: 500px) {
     font-size: 2rem!important;
   }
   @media only screen and (min-width: 800px) {
     font-size: 2.5rem!important;
   }
 }

.shop-title {
  padding-bottom:0.25rem;
  @media only screen and (max-width: 500px) {
    padding-bottom: 2rem;
    padding-left:5%;
  }
}

.shop-title-blog {
  padding-top: 0.25rem;
  font-size: 2.5rem;
  padding-bottom: 1rem;
  @media only screen and (max-width: 500px) {
    padding-bottom: 1rem;
    text-align: center;
  }
  @media only screen and (max-width: 800px) {
    padding-bottom: 0.75rem;
  }
  @media only screen and (min-width: $desktop) {
    font-size: 3rem;

  }
}

.icon-bottom {
  height:auto;
  width:25px;
}

.fda-logo {
  width:35px;
}

.ce-logo {
  width:20px;
}

.fertility-buttons {
  font-size: 0.9rem;
  width: 10rem;
}

.img-head {
  font-size: 2.5rem;
  @media only screen and (min-width: 500px) and (max-width: $desktop) {
    font-size: 2.5rem!important;
  }
  @media only screen and (min-width: $desktop) {
    font-size: 3.5rem!important;
  }

}

.home-blog {
  @media only screen and (max-width: 500px) {
    width:50%;
  }
}



.icon-txt {
  margin: auto;
  width: 80%;

}

.btn-home {
  @media only screen and (min-width:$tablet) and (max-width: 650px){
    font-size:1rem;
  }
}

.btn-rectangle {
  border-radius: 5px!important;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  background-color: #F0879B;
  border-color: transparent;
}

.home-boxes {
  background-color: whitesmoke;
  height: 11vw;
  border-radius: 0;
  box-shadow: none;

  @media only screen and (max-width: 700px){
    height: 36vw;
    padding-right: 0;
    padding-left: 5px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1840px){
    height: 20vw;
  }
  @media only screen and (min-width: 700px) and (max-width: 1000px){
    height: 40vw;
  }
  @media only screen and (max-width: 370px){
    height: 50vw;
  }
}

.home-box-padding {
  padding: 0.3rem;
}

.delivery {
  @media only screen and (max-width: 900px) {
    // padding-top: 0.5rem;
  }
}

.banner-home-delivery {
  padding-left: 45rem;
  padding-right: 40rem;
  // padding-bottom: 3rem;
  padding-top: 0;
  @media only screen and (max-width: 900px){
    padding-left: 3rem;
    padding-right: 1rem;
    // padding-bottom: 4rem;

  }
  @media only screen and (min-width: 900px) and (max-width: 2043px){
    padding-left: 8rem;
    padding-right: 4rem;
  }

}

.banner-background {
  background-image: url("../_images/home/banner-background.svg");
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100vw 60vw;
  height: 2rem!important;
  padding: 0;

}

.arrow-icon-boxes {
  width: 0.8rem;
 margin-left: 8vw;

  @media only screen and (max-width: $tablet){
    margin-left: 0;
  }
  @media only screen and (min-width: 700px) and (max-width: 1000px){
    margin-left: 7rem;
  }

}

.small-font-size {
  font-size: 1.2rem;
  @media only screen and (max-width: 700px){
    font-size: 0.8rem;
  }
}

.small-icon-boxes {
  width: 2rem;
  margin-right: 5rem;

  @media only screen and (min-width: 1000px) {
    width: 4rem;
  }
}

.tablet-correction {
  @media only screen and (min-width: 700px) and (max-width: 1000px){
    width: 4rem!important;
  }
}

.mobile-icons {
  padding-top: 2rem;
}

.fert-evol-img {
  width: 70%;

  @media only screen and (min-width: 700px) and (max-width: 1000px){
    padding-top: 3rem;
  }

}

.is-bolder {
  font-weight: bolder;

}

.is-uppercase {
  text-transform: uppercase;
}

.m-auto {
  margin: auto;
}

.btn-round-absolut {
  position: absolute;
  top: 375px;
  right: 272px;
  width: 150px;
  height: 150px;
  border-radius: 50%!important;
  background-color: #9AFCE4;
  color: black;
  transform: rotate(15deg);

  @media only screen and (max-width: $desktop){
    top: 30px;
    right: 30px;
  }
}

.mobile-view {
  @media only screen and (min-width: 300px) and (max-width: $tablet){
    width: 16rem;
  }
}

.margin-correction {
  margin-bottom: 0!important;
}

.button-section {
  @media only screen and (min-width: 350px) and (max-width: 500px){
    height: 33vw;
  }

  @media only screen and (max-width: 350px){
    height: 38vw;
  }
}

.checked {
  color: orange;
}

.half-checked {
  color: orange;
}

.columns {
  @media only screen and (max-width: 500px) {
    margin: 0!important;
  }
}

.padding-0-mobile {
  @media only screen and (max-width: 500px){
    padding-top: 0;
    padding-bottom: 0;
  }
}

.padding-correction-checkmark{
  @media only screen and (max-width: 500px){
    padding-left: 0;
  }

}

.appicon {
  vertical-align: middle;
  margin-left:2vw;
  margin-right:2vw;
}

.menu-shop-btn {
  height: 2.5rem;
  width: 5rem;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 25px !important;
  font-size: 1.25rem;
  font-weight: 300;
  border-width: 0;
  color: white!important;
  background-color: $primary;
  padding-top: 0.75rem!important;
  top:-5px;
  padding-left:18px;
  @media only screen and (max-width:1063px) {
    color:$primary!important;
    background-color: white;
    height: 3.75rem;
    width: 10rem;
    margin: auto;
  }
}
.menu-shop-btn:hover {
  color:$primary!important;
  background-color:white;
  // border-color: $primary;
  // border-width:1px;
}
