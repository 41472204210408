@import "variables";

html.has-navbar-fixed-top,
body.has-navbar-fixed-top {
  padding-top: $navbar-height-mobile;

  @media only screen and (min-width: $tablet) {
    padding-top: $navbar-height-tablet;
  }

  @media only screen and (min-width: $desktop) {
    padding-top: $navbar-height-desktop;
  }
}

.nav-container {
  @media only screen and (min-width: 1500px) {
    background-color: $block-primary-color;
    width: 100%;
    align-items: center;
    z-index: 30;
    top: 0;
    padding-top: 85px;
    left: 0;
    position: fixed;
  }
}
.nav-wide {
  @media only screen and (min-width: 1500px) {
    max-width: 1216px!important;
    margin: auto;
    top: -0.1rem!important;
  }
}
.navbar-link{
  font-size: $size-4;
  padding-top: 0;
  @media only screen and (min-width: $desktop) {
    margin-left: 20px;
    font-size: $size-6;
  }
  letter-spacing: 0.6px;
  // transition: $transition-time;

  &--active {
    color: $dark-color;
    font-weight: 500;
  }
  &--es {
    font-size: 0.9rem;
    margin-left: 10px;
  }
  &--fr {
    font-size: $size-6;
    margin-left: 10px;
  }
  @media only screen and (min-width: 1500px) {
    margin-left: 40px;
    font-size: 1.1rem;
  }
}
.navbar-hide {
  @media only screen and (max-width: 1063px) {
    position: relative !important;
    padding-top: 0!important;
  }
}
.container__shrink {
  @media only screen and (min-width: 1500px) {
    padding-top: 0;
    // transition: .84s;
    z-index: 30;
    height: 51px;
    position: fixed;
    padding-bottom: 0;
    border-bottom: 1px solid $grey-lighter;
  }
}
.navbar__shrink:not(.is-active) {
  padding-top: 20px;
  padding-bottom : 0;
  border-bottom: 1px solid $grey-lighter;
  .navbar-brand {
    img {
      width: 159px;

      @media only screen and (max-width: 350px) {
        width: 149px;
      }
    }
  }
  .navbar-item {
    padding-top: 1rem;
    line-height: 1.15;
  }
  .navbar-item.is-primary {
    padding: 0.85rem 0.75rem 0.5rem 0.75rem;
    box-sizing: border-box;
    border: 2px solid $primary;
  }
  * .locale-mobile {
    top: 0.1rem;
  }
}
.navbar {
  // transition: $transition-time;
  padding: 10px 3rem;
  max-width: none;
  width: 100vw;

  .dropdown-menu {
    min-width: initial; // Ensures empty language dropdowns are not displayed
  }

  .navbar-brand {
    margin-left: 0 !important;
    > a {
      z-index: 100;
      display: flex;
      align-items: center;

      > img {
        width: 185px;
        // transition: $transition-time;
      }
    }
  }

  .navbar-brand.is-active {
    > a {
      > img {
        @media only screen and (max-width: 1063px) {
          -webkit-filter: invert(100%);
          filter: invert(100%);
        }
      }
    }
  }

  @media only screen and (max-width: 1063px) {
    padding-left: 1.5rem;
    padding-right: 0;
  }

  @media only screen and (min-width: $desktop) {
    padding-top: 25px;
  }

  .navbar-item {
    // transition: $transition-time;
    padding: 1rem 0 0 0;
    line-height: 1.15;
  }
  .navbar-menu {
    align-items: center;
  }
  .navbar-menu ul {
    margin-bottom: 0;

    @media only screen and (min-width: $desktop) {
      display: flex;
    }
  }
}
// navbar mobile
@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
@media screen and (max-width: 1063px) {

  .navbar-start {
    overflow: hidden;
  }

  .navbar-link--active {
    color: #fff;
    font-weight: 500;
  }
  .navbar-menu {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    transition: opacity .35s, visibility .35s, height .35s;
    box-shadow: none !important;
    padding: 0;
    background: linear-gradient(90deg, #C45292, #F39999);

    &.is-active {
      opacity: 1;
      height: 31rem;

      .navbar-item {
        animation: fadeInRight .5s ease forwards;
        opacity:0;

        @for $n from 2 through 10 {
          &:nth-of-type(#{$n}) {
            animation-delay: calc(.2 + .5 * #{$n})s;
          }
        }
      }
    }

    .navbar-start {
      list-style: none;
      margin: 0 auto;
      display: inline-block;
      position: relative;
      //height: 100%;
      padding-top: 60px;
      font-size: 50px;
      text-align: center;
      width: 100%;

      .navbar-item {
        display: block;
        height: 20%;
        min-height: 50px;
        position: relative;

        a {
          display: block;
          position: relative;
          color: $white;
          text-decoration: none;
          overflow: hidden;
        }
      }
    }
  }

  .navbar-item:hover{
    color: white !important;
  }
}
.hide-tablet {
  @media only screen and (max-width: $desktop) {
    visibility:hidden;
    position: absolute;
  }
}
.show-mobile {
  @media only screen and (min-width: $tablet) {
    visibility:hidden;
    position: absolute;
  }
}
.home-hide {
  @media only screen and (max-width: $tablet) {
    visibility:hidden;
    position: absolute;
  }
}

/*
.locale-mobile {
  padding-top: 0.15rem;
  padding-right: 23px;
  font-size: 1.05rem;
}

.locale-dropdown {
  display: block;
  margin-top: 0.2rem;

  @media only screen and (max-width: 1000px) {
    margin-top: 0;
  }
  .navbar-burger.is-active + & {
    // transform: translateY(12px);
  }

  @media only screen and (min-width: $desktop) {
    position: relative;
    top: 0;
    right: 0;
    // transform: none;
  }

  .locale.dropdown-trigger {
    height: 2.2rem;
    padding-right: 0;
    padding-left: 0;

    @media only screen and (min-width: $desktop) {
      padding-top: 0.6rem;
      padding-right: 0.75rem;
     // padding-left: 0.75rem;
    }

  }

  .fi {

    border-radius: 50%;
    width: 25px;
    height: 25px;
    margin-left: 5px;
    float:right;
    background-size: auto 100%;

    @media only screen and (max-width: $desktop) {
      right: 9px;
      position: absolute;
    }
  }

  .locale {
    background: none;
    border-color: transparent;
    // padding: 0.5rem 0.75rem 0 0.75rem;
    width: 100%;
    font-weight: 200;
    display: inline-flex;
    font-size: $size-7;
    color: $text-color-3;
    cursor: pointer;

    .text {
      width: 60px;
      line-height:27px;
    }

    &.dropdown {
      padding-left: 20px;
      padding-top: 3px;

      a {
        font-size: $size-7;

        line-height:26px;
        color:#646464;

        @media only screen and (max-width: $desktop) {
          display: block;
        }
      }

      a:hover {
        color: $text;
      }
    }
  }

  .locale:hover {
    color: $text;
  }

  .dropdown-menu {
    width: 100vw;
    min-width: auto;
    max-width: 4.5rem;
    background: white;
    padding: 0.25rem 0.75rem 0.5rem 0.75rem;

    @media only screen and (min-width: $desktop) {
      width: auto;
      max-width: none;
    }
  }

  &__anker-down {
    background: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="iso-8859-1"?> <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"> <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"  width="612px" height="612px" viewBox="0 0 612 612" style="enable-background:new 0 0 612 612;" fill="rgb(150,150,150)" xml:space="preserve"> <g> <g id="_x31_0_34_"><g><path d="M604.501,134.782c-9.999-10.05-26.222-10.05-36.221,0L306.014,422.558L43.721,134.782 c-9.999-10.05-26.223-10.05-36.222,0s-9.999,26.35,0,36.399l279.103,306.241c5.331,5.357,12.422,7.652,19.386,7.296 c6.988,0.356,14.055-1.939,19.386-7.296l279.128-306.268C614.5,161.106,614.5,144.832,604.501,134.782z"/> </g> </g> </g> </svg>') no-repeat;
    background-size: 20px 17px;
    height: 17px;
    width: 20px;
    margin-right: 14px;
    // transform: translateY(0.4rem);
  }

  &.hoverable:hover,
  &.hoverable.is-active {
    > .dropdown-menu {
      box-shadow: 0.25rem 0.25rem 0.25rem rgba(0,0,0,0.1);

      @media only screen and (min-width: $desktop) {
        display: block;
      }
    }
  }
}
*/


.shop-menu {
  display: block;
  width: $navbar-height;
  text-align: center;
}


// Additional Classes
//
// Bulma Overwrite
.navbar-right {
  cursor: pointer;
  display: flex;
  height: 3.25rem;
  position: relative;
  margin-left: auto;
  z-index: 10;
  justify-content: flex-end;
  align-items: center;
}

.shop-sticky {
  @media only screen and (max-width: $desktop) {
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;
  }
}
.shop-padding {
  @media only screen and (max-width: $desktop) {
    padding-top: 2.4rem!important;
  }
}
.shop-btn {
  margin-right: 1rem;
}
.navbar-burger:hover{
  background-color: transparent;
}

.shop-icon {
  width:1.5rem;
  height:1.5rem;
  filter: invert(21%) sepia(68%) saturate(5%) hue-rotate(
      315deg
  ) brightness(86%) contrast(103%);
}
.navbar-menu {
  display: block;
  @media only screen and (min-width:$desktop){
    display:flex!important;
  }
}
