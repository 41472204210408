@import "variables";

.ma {
  margin:auto;
}

.girls-img {
  height: auto;
  max-height: 525px;
  @media only screen and (max-width: $tablet) {
    width: 100vw;
    max-width: 100vw;
    //margin-left: -16px;
    height: auto;
  }
}

.features_us {
  margin: 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.icon {
  width: 55px;
  height: 60px;
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.column-img-us {
  margin-left:-18px;
  padding-bottom: 2px;
}

.column-us {
  margin: auto;
  overflow-y: auto;
  .text-modal {
    width: 95%;
  }
}

.gradient-line {
  content: "";
  width: 25%;
  height: 1px;
  margin: 1rem 0 2rem 0;
  background: linear-gradient(90deg,$gradient-left-color3,$gradient-right-color);
}
