.section {
  background-color: $block-primary-color;
}
.sectionwhite {
  background-color: $block-secondary-color;
}

.container--wide {
  max-width: $widescreen;
  width:100%;
  margin-right: auto;
  margin-left: auto;
}

.container--narrow {
  max-width: 800px;
}

.sectionhighlight {
  background-color: $block-highlight-color;
}
