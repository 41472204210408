@mixin text-gradient($from, $to, $topercent) {
  color: $primary;
  background: -webkit-linear-gradient(0, $from, $to $topercent);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 0.2em; // fix cut off descenders
}

@mixin text-gradient-reset() {
  background: none;
  -webkit-text-fill-color: inherit;
  padding-bottom: 0em;
}
