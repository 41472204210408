/*
femSense CSS
2018 by SteadySense
 */

@import "variables";
@import 'helpers/gradient';

.overflow-fix {
  overflow:hidden
}

body {
  //  height:100vh;
  overflow-x: hidden;
}

cite {
  font-style: normal;
  color: $text-light;
}

p, ul, ol {
  margin-bottom: 1em;
}

blockquote {
  margin: 2rem 1rem;
  font-size: 1.125rem;
  font-weight: normal;

  cite {
    display: block;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 300;
  }

  @media only screen and (min-width: $tablet) {
    margin: 3rem 3rem;
    font-size: 1.5rem;
  }
}

// Components & pages

.page {
  ul {
    padding-left: 40px;
    list-style: none;
  }
  li {
    padding: 3px 0;
  }
  li::marker {
    color: black;
  }

  ol {
    padding-left:20px;

    li {
      padding-left:5px;
    }
  }
}

// Footer

.footer {
  padding: 0;

  .social {
    img {
      height: 20px;
      width:auto;
      padding: 0 0.6rem;
      @media screen and (max-width: $tablet) {
        padding: 0 15px;
      }

    }
  }

  .copyright {
    opacity: 0.8;
    font-size: $size-7;
    color: $text-color-3;
    line-height: 5px;
    //vertical-align: super;
    align-self:center;
  }

  nav {
    .group,
    .group > button {
      font-weight: 500;
      font-size: $size-6;
      color: $grey;
      padding-bottom: 0.625rem;
      user-select: none;
    }

    .payments {
      hyphens: auto;
      img {
        background: white;
        width: auto;
        height: 1.75rem;
        border-radius: 3px;
        border: 2px solid #e4e4e4;
      }
    }

    a, p {
      color: rgba(64, 64, 64, 0.6);
      font-size: $size-7;
      letter-spacing: 1px;
      line-height: 25px;
      text-decoration: none !important;
      transition: $transition-time;
    }

    .houdini-toggle {
      border: none;
      background: transparent;
      width: 100%;

      border-top: 1px solid $grey-lighter;
      padding-top: 0.8rem;
      padding-left: 1rem;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .houdini-toggle-icon {
      height: 100%;
      display: block;
      margin-left: 0.75em;

      font-weight: 200;
      font-size: 1.5rem;
      color: $primary !important;
    }

    .houdini {
      padding-left: 2rem;
    }
    .houdini.is-expanded {
      display: block;
    }

    li {
      @media screen and (max-width: $tablet) {
        padding: 0.75rem 0;
      }
    }
  }
}

.stories__columns {
  padding: 0.25rem;
  @media only screen and (min-width: $tablet) {
    padding: 0.75rem;
  }
}

.stories__post {
  overflow: auto; // clear floats
  background: linear-gradient(90deg, rgba(190, 75, 145, 0.3), rgba(243, 152, 153, 0.3)) no-repeat left bottom;
  background-size: 100% 1px;
}

.stories__img {
  float: left;
  width: 100%;
  &:hover {
    transform: scale(1.1);

  }
  @media only screen and (min-width: $tablet) {
    width: 100%;
    &:hover {
      transform: scale(1.1);

    }
  }
}

.post__meta {
  padding-bottom: 40px;
  margin-bottom: 40px;
  background: linear-gradient(90deg, rgba(190, 75, 145, 0.3), rgba(243, 152, 153, 0.3)) no-repeat left bottom;
  background-size: 100% 1px;
}

.post {
  ul {
    list-style: disc;
    padding-left: 20px;
    padding-bottom: 25px;
  }
  #sources ~ p a,
  #quellen ~ p a {
    color: $text-color-2;
  }
  #sources ~ p a:hover,
  #quellen ~ p a:hover {
    color: $text-highlight-color;
  }
}

// Bulma radio-checkbox
.is-checkradio[type="radio"] + label::before,
.is-checkradio[type="radio"] + label::after,
.is-checkradio[type="radio"]:hover:not([disabled]) + label::before,
.is-checkradio[type="radio"]:hover:not([disabled]) + label::after {
  border-color: gray !important;
}

.is-checkradio[type=radio] {
  + label::before {
    display: none !important;
    content: "" !important;
  }
  + label::after {
    display: none !important;
    content: "" !important;
  }
}

.is-checked {
  border: 3px solid $primary!important;
}


// Cookie Consent overwrite
.cc-window {
  z-index: 2000000001;  // To be above tawk.io which defaults to 2000000000
}
@media screen and (max-width: $tablet) {
  #cookieconsent\:desc {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 8px;
  }
  .cc-window {
    padding: 18px;
    z-index: 9999;  // Reset tawk.io override
  }
  .cc-link {
    font-size: 14px;
  }
}

.banner {
  text-align: center;
  color: $block-secondary-color;
  margin-bottom: 0.5rem;
  height: 2.25rem;
  font-size:1.25rem;
  padding-top: 0.3rem;
  background: linear-gradient(90deg, $gradient-left-color3, $gradient-right-color3);

  @media only screen and (min-width: $desktop) {
    padding-top: 0.5rem;
    height: 3.0rem;
    font-size: 1.5rem;
  }
}

.banner-home-1 {
  text-align: center;
  color: #333333;
  background-color: #9AFCE4;
  width:100%;
  z-index:10;
  transition: $transition-time;
  height: 3.5rem;
  @media only screen and (min-width: 500px ) {;
    height: 3.35rem;
  }
}

.banner-home {
  text-align: center;
  //color: $block-secondary-color;
  color: white;
  // position:fixed;
  width:100vw;
  max-width:1032px;
  left:-16px;
  position: relative;
  z-index:10;
  transition: $transition-time;
  /* margin-bottom: 0.5rem; */
  height: 2.25rem;
  font-size:1.25rem;
  padding-top: 0.35rem;
  //background: linear-gradient(90deg, $gradient-left-color3, $gradient-right-color3);
 // background-image: url("../_images/blog/banner-background-LONG.jpg");
  background-color: $primary;
  background-position: center;
  @media only screen and (max-width: 560px) {
    // position: static;
    padding-top: 0.7rem;
    // left:-32px;
    height: 2.5rem;
    font-size: 1rem;
    background-position: right;
  }
  @media only screen and (min-width: 560px) and (max-width: 1024px) {
    //position: static;
    padding-top: 0.5rem;
    // left:-16px;
    height: 2.75rem;
    font-size: 1.25rem;
    background-position: left;
  }
}

#coupon1.banner-home {
  left:0px;
}

.banner-home-shrink {
    transition: $transition-time;
    transform: translateY(-20px);
    @media only screen and (min-width: $tablet) and (max-width: 1000px) {
      transition: 0.25s;
      transform: translateY(-24px);
    }
  }

.banner-buy {
  height: 1.5rem;
  width: auto;
  position: absolute;
  right: 1.2rem;
  padding-left: 0.5rem!important;
  padding-right: 0.5rem!important;
  background-color: transparent!important;
  border-color: white;
  padding-top: 0.45rem;
  border-radius: 25px!important;
  &:hover {
    opacity: 0.7;
    border-color: white;
    color: white;
  }
  @media only screen and (max-width: $iphoneSE) {
    right: 0.5rem;
  }
}

.banner-text {
  @media only screen and (max-width: $desktop) {
    position: absolute;
    left: 7vw;
    font-size: 1.1rem;
  }
  @media only screen and (max-width: $iphoneSE) {
    left: 0.4rem;
    font-size: 1rem;
  }
}

.modal-content {
    margin: 0 20px;
    max-height: calc(100vh - 10px);
    overflow: auto;
    position: relative;
    width: 100%;
    background-color: white;
    @media only screen and (min-width: $desktop) {
      margin: 0 20px;
      max-height: calc(100vh - 100px);
      overflow: auto;
      position: relative;
      width: 100%;
    }
}

.m-content {
    width: 600px!important;
    height: 400px!important;
}

.ifu-link {
  font-size: 1rem;
  @media only screen and (min-width: $desktop) {
    font-size: 1.15rem;
  }
}

.version-text {
  display: inline-block;
}

.nopadding {
  padding:0!important;
}

.icon-bottom-medium {
  height: 30px;
  width: 30px;
  max-width: max-content;
}

.text-blur {
  text-shadow: 4px 4px 12px #3f3f3f;
}

.territory-title {
  margin: 2rem 0 2rem 0;
}

.territory-flag {
  height: 4rem;
}
.territory-body {
  margin: 1rem 0;
}
.territory-col {
  margin:4rem 0;
  .fi {
    width: 5rem;
  }
}

.is-narrow {
  max-width:800px!important;
}

// bluma overwrite - columns
.columns:last-child {
  margin-right:0;
}

// carousel fix: stories
.story-fix {
  height:23rem!important;
}

.carousel1 {
  @media only screen and (max-width: 500px) {
    height:26rem;
  }
}

.carousel2 {
  @media only screen and (max-width: 500px) {
    height:30rem;
  }
}

.carousel3 {
  @media only screen and (max-width: 500px) {
    height:32rem;
  }
}

.carousel4 {
  @media only screen and (max-width: 500px) {
    height:21rem;
  }
}

// overwrite section
.section {
  @media only screen and (min-width: $desktop) {
    padding: 3.5rem 1rem;
  }
}

.narrow {
  @media only screen and (min-width: $desktop) {
    padding: 1.5rem 1rem!important;
  }
}

// IFU
.ifu-link {
  margin:1rem 1rem 3rem 1rem;
  li {
    margin-bottom: 1rem;
  }
}

.ifu-text {
  padding-left: 1rem;
}

// bulma overwrite: badge
.badge {
  font-size: 0.75rem;
  height:16px;
  width: 16px;
  line-height:10px;
  border-radius: 50%;
  padding: 0.1rem 0;
  transform: translate(20%,-20%);
  box-shadow: none;
  background-color: $primary;
  color: $white;
  position: absolute;
  right: 0;
  top: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 2px solid transparent;
}

.nl-banner {
  padding:2.5rem;
}

.nl-banner-text {
  transform:translateY(-130px);
  padding-left: 38%;
  padding-right: 5%;
  @media only screen and (min-width: 700px) and (max-width: 800px) {

    transform:translateY(calc(-16.5vw));
  }
  @media only screen and (max-width: 700px) {
    padding:0;
    margin:auto;
    text-align:center;
    width:90%;
    transform:translateY(-40vw);
  }

  > p {
    font-size: 1.25rem;
    color:#fff;
    margin-bottom:0.5rem;
    @media only screen and (max-width: 700px) {
       font-size:5vw;
    }
    @media only screen and (min-width:700px) and (max-width:800px) {
      font-size:2.5vw;
    }

  }
  > button {
    color: #fff;
    background-color: transparent;
    border-width: 1px;
    border-color: #fff;
    font-size:1.5rem;
    @media only screen and (max-width: 700px) {
      font-size: 1.25rem;
    }

  }
}

.nl-banner-body {
  height:calc(100vw * 250 / 1000);
  max-height:200px;
  margin-bottom:1rem;
  @media only screen and (max-width: 700px) {
    height: calc(100vw * 475 / 500);
    margin:0 0 1rem -1rem;
    width:100vw;
    max-height: 670px;
  }
}

.nl-btn {
  border-color: #ffffff;
  border-radius: 25px;
  border-width: 1px;
  height: 2.5rem;
  width: 90%;
  max-width: 18rem;
  font-size: 1.25rem;
  font-weight: 300;
  background-color: transparent;
  padding:0;
}

.nl-btn-new {
  background-color: transparent ;
  border-color: transparent;
  height: 5rem;
  padding-top: 0;
  padding-bottom: 0;

  @media only screen and (min-width: 300px) and (max-width: $tablet)  {
    height: 2rem;
  }
}

.nl-btn:hover {
  border-color:white;
  background-color: white;
  color: $text-highlight-color;
}

.nl-banner-img-desktop {
  @media only screen and (max-width: 700px) {
    display: none;
  }
}

.nl-banner-img-mobile {
  width:100vw;
  height:auto;
  @media only screen and (min-width: 701px) {
    display: none;
  }
}

.nl-columns {
  @media only screen and (min-width: 700px){
    display:flex;
  }
}

.survey-btn {
  padding-right: 4rem;
  padding-left: 4rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.shop-banner {
  position:fixed;
  width:90%;
  bottom:5vh;
  right:5%;
  z-index:100;
  background-color: whitesmoke;
  border-width:1px;
  border-color: #7f7f7f;
  border-radius:10px;
  border-style: solid;
  box-shadow: 2px 2px #7f7f7f;
  color: #333333;
;
  img {
    height:50px;
    margin:0.5rem;
  }
  * p {
    margin:0;
    padding:0;
    font-size: 0.9rem;
    @media only screen and (max-width: 350px)
    {
      font-size: 0.7rem;
    }

  }
  * p:first-child {
    font-size: 80%;
    @media only screen and (max-width: 350px)
    {
      font-size: 0.6rem;
    }
  }
  * p:last-child {
    font-size: 80%;
    @media only screen and (max-width: 350px)
    {
      font-size: 0.6rem;
    }
  }
  span {
    padding-left:1.5rem;
    font-size:125%;
    color:#FF6E8C;
  }
  button {
    border-radius: 25px;
    font-size: 0.9rem;
    background-color: #FF6E8C;
    border-color: transparent;
    color: white;
    height:2rem;
    width:3.5rem;
    @media only screen and (max-width:350px) {
      font-size: 0.8rem;
    }
  }
}

.shop-banner-columns {
  align-items: center;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1s;
  animation-name: fade;
  animation-duration: 1s;
}

@-webkit-keyframes fade {
  from {opacity: 0; bottom:-10vh;}
  to {opacity: 1; bottom:5vh;}
}

.mb-social {
  margin-bottom: 20px!important;
  @media only screen and (max-width: $tablet) {
    margin-bottom: 100px!important;
  }
}

.shop-icons {
  width: 1.5rem;
}

.footer_new {
  text-align:center;
  padding-top: 10px;

  .footer_headline {
    padding-top: 20px;
    text-transform: uppercase;
  }
  .gotop {
    text-transform: uppercase;
    text-align: right;
    padding-right: 10px;
    font-weight: 800;
  }
  p {
    margin: 0;
  }

}

.section {
  position:relative;
}

#extshoplink {
  width:100%;
}

.img-press {
  height:70vw;
}

.h70 {
  height:70vw;
}

.section_contact {
  text-align: center;
  color: $block-secondary-color;
  background: linear-gradient(90deg, $gradient-left-color,$gradient-left-color, $gradient-right-color2);

  cursor: pointer;
  padding-top: 3rem!important;
  padding-bottom: 3rem!important;
  a {
    color: $block-secondary-color;
  }
  .button:hover {
    color: $primary;
  }
}
