@font-face {
  font-family: 'Basetica';
  src: url(../_src/fonts/Basetica-Light.woff2) format('woff2'),
  url(../_src/fonts/Basetica-Light.woff) format('woff'),
  url(../_src/fonts/Basetica-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basetica';
  src: url(../_src/fonts/Basetica-Medium.woff2) format('woff2'),
  url(../_src/fonts/Basetica-Medium.woff) format('woff'),
  url(../_src/fonts/Basetica-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@import "variables";
@import "~bulma";

// Global
body {
  font-family: $primary-font-family;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;
  line-height: 1.5;
  color: $text-color;
  background-color: $block-primary-color;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  // overflow-x: hidden;
}

main {
  width: 100%;
}

a {
  color: $link;
  text-decoration: none;

  &:hover {
    color: $text-highlight-color;
  }
}

::selection {
  background: $gradient-left-color;
  color: $block-primary-color;
}
