.marquee-section__marquee{
  height: 100%;
  margin: 0;
  color: $block-secondary-color;
  white-space: nowrap;
  line-height: 2.5rem;
  font-size: 1.25rem;
  @media only screen and (min-width: $tablet) {
    line-height: 3.5rem;
    font-size: 1.75rem;
  }
}
.marquee-section {
  padding: 0;
  height: 2.5rem;
  background: linear-gradient(90deg, $gradient-left-color3, $gradient-right-color3);
  @media only screen and (min-width: $tablet) {
    height: 3.25rem;
  }
  a {
    display: block;
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  animation: scroll-left-mobile 10s linear infinite;

  @media only screen and (min-width: $tablet) {
    animation: scroll-left 30s linear infinite;
  }
}

@keyframes scroll-left {
  from {
    text-indent: 100%
  }
  to {
    text-indent: -35%
  }
}

@keyframes scroll-left-mobile {
  from {
    text-indent: 100%
  }
  to {
    text-indent: -150%
  }
}
