@import "variables";

.kinderwunsch__facts__title{
  max-width: 825px;
}
.kinderwunsch__facts__text{
  max-width: 825px
}
.kinderwunsch__facts__oval, .kinderwunsch__facts__system {
  height: 5rem;
  @media only screen and (min-width: $tablet) {
    height: 100px;
  }
}
.kinderwunsch__quotation{
  .quote-img {
    max-width: 40%;
    margin: 0 auto;
    @media only screen and (min-width: $desktop) {
      max-width: 75%;
    }
    img {
      border-radius: 50%;
    }
  }
}
.kinderwunsch__awards {
  text-align: center;
  padding-top:41px;
  padding-bottom:35px;
  @media only screen and (max-width: $tablet) {
    padding-top:25px;
    padding-bottom:10px;
  }
}

.compatibility {
  @media only screen and (min-width:$tablet){
    margin-top: 2rem!important;
    margin-right: auto;
    margin-left: auto;
    text-align: center!important;
  }
}
