@import "variables";

div#mc_embed_signup {
  background:#fff;
  clear:left;
  .mc-field-group {
    width: 80%;
    margin: auto;
  }
}
div.mce_inline_error {
  padding-bottom: 0!important;
  margin-bottom: 0!important;
}
.response {
  font-size: 0.8rem!important;
  width: auto!important;
  padding-top: 0!important;
  margin-top: 0!important;
}
form#mc-embedded-subscribe-form {
  // display: inline;
  width: auto;
  //margin-right: 10px;
  .mc_fieldset {
    border:none;
    min-height: 0;
    padding-bottom: 0;
  }
}
.subscribe-banner {
  @media only screen and (max-width: 500px) {
    visibility:hidden;
    position: absolute;
  }
}
.newsletter-header {
  font-size: 1.2rem;
}
.m-content {
  width: 100%!important;
  height: 465px!important;
  @media only screen and (min-width: 600px) {
    width: 600px!important;
    height: 375px!important;
  }
}
.m-content_us {
  @media only screen and (max-width: 600px) {
    height:450px!important;
  }
}
.modal-btn {
  width: 600px;
  height: 35px;
  position: relative;
  @media only screen and (max-width: 600px) {
    width: 100%!important;
  }
}
.modal-close {
  position: absolute;
  right: 0;
  top: 0;
}
.modalContent__image {
  @media only screen and (min-width: 500px) {
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../_images/femsense-004_newsletter.jpg');
  }
}
.text-modal{
  color: black;
  // padding-bottom: 10px;
  text-align: left;
  width:80%;
  margin-left: auto;
  margin-right: auto;
  padding-left:0;
  font-size: 0.75rem;
  ul {
    list-style-type: disc;
    padding-left: 1.5rem;
  }
}
.email-modal{
  font-weight: 600!important;
  font-size: 0.9rem!important;
  color: black;
  padding-bottom: 10px;
}

.email-modal-nl {
  color: white;
  padding-bottom: 10px;
  font-size:1.25rem;
}
.email-input {
  height:1.75rem;
  margin-left:10px;
  width:90%;
  max-width:15rem;
  border-color:transparent;
  border-radius:5px;

  @media only screen and (max-width: 600px){
    margin-left: 50px;
  }
}
input:focus {
  border-color: #FABE96;
}
.nl-email {
  text-align: left;
  padding-left:3rem;
  padding-top:10px;
  margin:0;
}
.button-subscribe{
  background-color: $primary!important;
  width:85%;
  height:2rem;
  font-size:1rem;
  padding-top: calc(0.2em - 1px);
  padding-bottom: calc(0.2em - 1px);
}

.mobile-btn-content{
  display: block;
  @media only screen and (max-width: 500px) {
    display: contents;
  }
}

.btn-size-fix{
  padding-bottom: calc(1.5em - 1px);
  padding-top: calc(1.5em - 1px);
}

.success-message {
    //color: green;
    font-style: italic;
    margin-top: 5px;
    font-size:65%;
    > a {
      color:black;
      font-style:normal;
    }
}
.error-message {
    //color: red;
    font-style: italic;
    margin-bottom: 1em;
    font-size: 70%;
    > a {
      color: black;
      font-style: normal;

    }
}
.nl-columns {
  margin: 0!important;
  * p {
    margin:0;
    font-size:1.25rem;
    padding-left:10px;
    text-align:left;
  }
  * p:first-child {
    padding-bottom:1rem;
  }
  @media only screen and (min-width:600px) {
    display:flex;
  }
}
.flex-col {
  flex-direction: column;
}
.nl-h2{
  color: $primary!important;
  padding-top: 10px;
  @media only screen and (min-width: 600px) {
    padding-top:0;
  }
}

.nl-body {
  text-align:left;
  padding-left:8%;
  input {
    width:91%;
    // max-width:200px;
    padding-left: 2%;
    height:2rem;
  }
}

.nl-col:first-child {
  @media only screen and (min-width: 600px){
    width:41.6666%;
  }

}
.nl-col {
  @media only screen and (min-width: 600px){
    width:58%;
  }

}
.nl-is-hidden-tablet {
  @media only screen and (min-width:600px){
    display:none;
  }
}
.nl-is-hidden-mobile {
  @media only screen and (max-width:599px) {
    display:none;
  }
}
